RoomDocumentsController.$inject = [
  "$state",
  "RoomConfig",
  "DocumentsService",
  "FileUploadService",
];

import {
  insightTrack,
  RoomDataroomBulkEvent,
  RoomDataroomMenuEvent,
} from "@app/insight";
import { LeftSidebarDocumentsTree } from "../services/DocumentsTreeService.ts";

export default function RoomDocumentsController(
  $state,
  RoomConfig,
  DocumentsService,
  FileUploadService,
) {
  var self = this;

  self.userPermissions = RoomConfig.userPermissions;
  self.RoomConfig = RoomConfig;
  self.selectedFolderId = undefined;
  self.Documents = DocumentsService;
  self.FileUploadService = FileUploadService;
  self.allowBulkDownload =
    RoomConfig.enableBulkDownload || RoomConfig.userPermissions.administrator;

  self.selectFolder = selectFolder;
  self.goToRecent = goToRecent;
  self.goToBookmarks = goToBookmarks;
  self.goToArchive = goToArchive;
  self.goToAiReports = goToAiReports;
  self.resetSearch = resetSearch;
  self.notifyDrag = notifyDrag;
  self.onItemDragAndDrop = onItemDragAndDrop;
  self.trackUploadFolders = trackUploadFolders;
  self.trackUploadFiles = trackUploadFiles;

  self.downloadItem = downloadItem;
  self.editCustomData = editCustomData;
  self.showActivity = showActivity;

  function selectFolder(folder, expandTree) {
    self.selectedFolderId = folder.id;

    if (expandTree) {
      LeftSidebarDocumentsTree.expandFolder(folder);
    }

    // reload folder without reloading documents controller
    $state.go(
      "documents.folder",
      { folderId: folder.id, query: self.searchQuery },
      { reload: "documents.folder" },
    );
  }

  function goToRecent() {
    $state.go("documents.recent", { query: self.searchQuery });
  }

  function goToBookmarks() {
    $state.go("documents.bookmarks", { query: self.searchQuery });
  }

  function goToAiReports() {
    $state.go("documents.reports", {});
  }

  function goToArchive() {
    $state.go("documents.archive", { query: self.searchQuery });
  }

  function resetSearch() {
    $state.go($state.$current.name, { query: "" }, { reload: false });
  }

  function downloadItem(item) {
    if (item.type === "file") {
      insightTrack(RoomDataroomMenuEvent.Download, { item: "file" });

      DocumentsService.documentDownload(item.id);
    } else if (item.type === "folder") {
      insightTrack(RoomDataroomMenuEvent.Download, { item: "folder" });

      DocumentsService.bulkDownload([item.id], []);
    }
  }

  function trackUploadFolders() {
    insightTrack(RoomDataroomBulkEvent.Upload, { item: "folder" });
  }

  function trackUploadFiles() {
    insightTrack(RoomDataroomBulkEvent.Upload, { item: "file" });
  }

  function editCustomData(item) {
    DocumentsService.editCustomDataModal(item);
  }

  function showActivity(item) {
    DocumentsService.showDocumentActivity(item);
  }

  function notifyDrag(folder, isDragging) {
    const currentFolder =
      folder ?? DocumentsService.Folders[self.selectedFolderId];
    const label = currentFolder?.parent_id
      ? `the folder ${currentFolder.treeIndex} ${currentFolder.name}`
      : "your room";

    FileUploadService.notifyDrag(label, isDragging);
  }

  function onItemDragAndDrop(destFolder, items) {
    // we can not really disable drag to file (prolly because of ngf upload)
    // so double check it here
    if (destFolder.type !== "folder" || !destFolder.edit) {
      return false; // cancel drag and drop
    }
    const docs = [],
      folders = [];
    items.forEach((item) => {
      (item.type === "folder" ? folders : docs).push(item.id);
    });
    DocumentsService.moveOrCopyItems(folders, docs, false, destFolder.id);
    return true;
  }

  self.folderToUploadId = null;
  self.itemsToUpload = null;

  self.setItemsToUpload = (folder, files) => {
    if (Array.isArray(folder)) {
      files = folder;
      folder = null;
    }

    self.folderToUploadId = folder?.id ?? self.selectedFolderId;

    const isUploadingFolder = files.some((f) => !!f.webkitRelativePath);
    if (isUploadingFolder) {
      trackUploadFolders();
    } else {
      trackUploadFiles();
    }

    self.itemsToUpload = files;
  };

  self.clearItemsToUpload = () => {
    self.folderToUploadId = null;
    self.itemsToUpload = null;
  };

  self.selectUploadDialg = (folder, files) => {
    FileUploadService.dragLabel = undefined;
    self.setItemsToUpload(folder, files);
  };
}
